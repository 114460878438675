import React, {useEffect, useState} from "react";
import {
    Col, Container, Row, Nav, CardBody, Card,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    NavItem
} from "reactstrap";

import {Link} from 'react-scroll';
import CountUp from 'react-countup';

import {Link as Link2} from 'react-router-dom';
import * as Icon from 'react-feather';
import {Swiper, SwiperSlide} from "swiper/react";


import News from "../../component/News";
import Contact from "../../component/Contact";
import AgencyProject from "../../component/AgencyProject";

import SwiperCore, {Autoplay, Navigation} from 'swiper';
import "../../../node_modules/swiper/swiper.scss";
import "../../../node_modules/swiper/components/navigation/navigation.scss";
import "../../../node_modules/swiper/components/pagination/pagination.scss";

// Modal Video
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";

import Laptop from '../../assets/images/bg/laptop.png';
import About from '../../assets/images/about.jpg';
import CTA from '../../assets/images/bg/cta.png';
import Map from '../../assets/images/map.png';
import Logo from '../../assets/images/logo-light.png';

import Client1 from "../../assets/images/client/01.jpg";
import Client2 from "../../assets/images/client/02.jpg";
import Client3 from "../../assets/images/client/03.jpg";
import Client4 from "../../assets/images/client/04.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

SwiperCore.use([Autoplay, Navigation]);


export default function Startup() {


    const [videoModal, setModal] = useState(false);
    const [isOpen, setMenu] = useState(true);

    window.addEventListener("scroll", windowScroll);


    const serviceList = [
        {
            name: 'Jetty protection',
            description: 'Lorem ipsum dolor consectetuer Lorem simply dummy orem commo.'
        },
        {
            name: 'Rubber Fender',
            description: 'Lorem ipsum dolor consectetuer Lorem simply dummy orem commo.'
        },
        {
            name: 'Bollard',
            description: 'Lorem ipsum dolor consectetuer Lorem simply dummy orem commo.'
        },

        {
            name: 'Tangga kapal',
            description: 'Lorem ipsum dolor consectetuer Lorem simply dummy orem commo.'
        },
        {
            name: 'Kademeter',
            description: 'Lorem ipsum dolor consectetuer Lorem simply dummy orem commo.'
        },
        {
            name: 'Bearing pad',
            description: 'Lorem ipsum dolor consectetuer Lorem simply dummy orem commo.'
        },
    ]

    /**
     * Window scroll
     */
    function windowScroll() {
        const navbar = document.getElementById("navbar");

        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            navbar.classList.add("nav-sticky");
        } else {
            navbar.classList.remove("nav-sticky");
        }

        const mybutton = document.getElementById("back-to-top");

        if (
            document.body.scrollTop >= 500 ||
            document.documentElement.scrollTop >= 500
        ) {
            mybutton.classList.add("block");
        } else {
            mybutton.classList.add("none");

        }
    }

    const toggleMenu = () => {
        setMenu(!isOpen)
    }

    useEffect(() => {
        AOS.init({
            once: true,
            easing: "ease-out-cubic",
        });
        AOS.refresh();
    }, []);

    const bannerList = [
        {
            title: <>
                Rubber Fender Berkualitas Tinggi
                untuk <br/> Keamanan Pelabuhan dan Kapal
            </>,
            image: 'https://gilda.s3.nevaobjects.id/gilda/view-bridge-river_20240930_044540.jpg',
            description: `kami menyediakan rubber fender yang tangguh dan tahan lama untuk melindungi dermaga, pelabuhan, dan kapal Anda dari benturan keras..`,
            cta_url: '#'
        },

        {
            title: <>
                Solusi Terpercaya untuk Menghadapi Kekuatan Alam

            </>,
            description: `Lindungi jetty dan garis pantai Anda dari erosi dan cuaca ekstrem dengan teknologi perlindungan mutakhir kami.`,
            image: 'https://gilda.s3.nevaobjects.id/gilda/construction-works-cost-monaco%20%281%29_20240930_045014.jpg',
            cta_url: '#'
        },

    ]

    const clientLists = [
        "https://gilda.s3.nevaobjects.id/gilda/logo%20pt%20hajar%20aswad.png",
        "https://gilda.s3.nevaobjects.id/gilda/logo-yodha%20karya.jpg",
        "https://gilda.s3.nevaobjects.id/gilda/logo%20hutama.png",
        "https://gilda.s3.nevaobjects.id/gilda/ninfya.png",
        "https://gilda.s3.nevaobjects.id/gilda/logo-abipraya.png",
        "https://gilda.s3.nevaobjects.id/gilda/waskita-logo.png",
        "https://gilda.s3.nevaobjects.id/gilda/logo-pp.png",
        "https://gilda.s3.nevaobjects.id/gilda/logo%20sement.jpg",
        "https://gilda.s3.nevaobjects.id/gilda/logo-asdp.png",
        "https://gilda.s3.nevaobjects.id/gilda/logo-pelindo.png"
    ]

    return (
        <>
            <div>
                <nav id="navbar" className="navbar navbar-expand-lg nav-light fixed-top sticky">
                    <div className="container">
                        <NavbarBrand className="navbar-brand" href="/">
                            <span className="logo-light-mode">
                                 <img src={'https://gilda.s3.nevaobjects.id/gilda/logos-mcu%20%282%29.png'}
                                      className="l-dark" alt=""/>
                   <img src={'https://gilda.s3.nevaobjects.id/gilda/logos-mcu-mono.png'}
                        className="l-light" alt=""/>
                            </span>
                            <img src={'https://gilda.s3.nevaobjects.id/gilda/logos-mcu-mono.png'}
                                 className="logo-dark-mode" alt=""/>
                        </NavbarBrand>
                        <NavbarToggler onClick={toggleMenu}>
                            <Icon.Menu/>
                        </NavbarToggler>

                        <Collapse className={`navbar-collapse ${isOpen === true ? 'hidden' : 'show'}`}
                                  id="navbarSupportedContent">
                            <Nav className="navbar-nav ms-auto mb-2 mb-lg-0" id="navbar-navlist">
                                <NavItem>
                                    <Link activeClass="active" to="home" spy={true} smooth={true} duration={500}
                                          className="nav-link">Home</Link>
                                </NavItem>

                                <NavItem>
                                    <Link activeClass="active" to="service" spy={true} smooth={true} duration={500}
                                          className="nav-link">Tentang Kami</Link>
                                </NavItem>

                                {/*<NavItem>*/}
                                {/*    <Link activeClass="active" to="blog" spy={true} smooth={true} duration={500}*/}
                                {/*          className="nav-link">Blog</Link>*/}
                                {/*</NavItem>*/}
                                <NavItem>
                                    <Link activeClass="active" className="nav-link" to="contact" spy={true}
                                          smooth={true} duration={500}>Kontak Kami</Link>
                                </NavItem>
                            </Nav>

                        </Collapse>

                    </div>

                </nav>

                {/* Start Hero  */}
                <section className="swiper-slider-hero position-relative d-block vh-100" id="home">
                    <Swiper spaceBetween={0}
                            slidesPerView={1}
                            pagination={{clickable: true}}
                            loop

                            navigation
                            autoplay
                    >
                        {
                            bannerList.map((banner) => {
                                return (
                                    <>
                                        <SwiperSlide className="d-flex align-items-center overflow-hidden">
                                            <div className="slide-inner slide-bg-image d-flex align-items-center"
                                                 style={{background: `url(${banner.image}) center center`}}>
                                                <div className="bg-overlay"></div>
                                                <Container>
                                                    <div className="row justify-content-center">
                                                        <div className="col-12">
                                                            <div className="title-heading text-center">
                                                                <h1
                                                                    data-aos="fade-up"
                                                                    data-aos-duration="1000"
                                                                    className="display-5 text-white title-dark fw-bold mb-4">
                                                                    {banner.title}
                                                                </h1>
                                                                <p
                                                                    data-aos="zoom-in"
                                                                    data-aos-duration="1100"
                                                                    className="para-desc mx-auto text-white-50">
                                                                    {banner.description}
                                                                </p>
                                                                <div className="mt-4 pt-2">
                                                                    <Link2 to="#" className="btn btn-primary">Pelajari
                                                                        Selengkapnya</Link2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Container>
                                            </div>
                                        </SwiperSlide>
                                    </>
                                )
                            })
                        }


                    </Swiper>
                </section>

                {/* FEATURES START  */}
                <section className="section bg-light" id="service">
                    <Container>
                        <div className="row justify-content-center">
                            <Col lg={12}>
                                <div data-aos="fade-up" className="features-absolute rounded shadow px-4 py-5 bg-white">
                                    <Row>
                                        {
                                            serviceList.map((service) => {
                                                return (
                                                    <>
                                                        <div className="col-lg-4 col-md-6 px-2 mb-4">
                                                            <div className="d-flex features feature-primary">
                                                                <div className="feature-icon text-center">
                                                                    <i className="mdi arrange-bring-forward rounded h4"></i>
                                                                </div>
                                                                <div className="flex-1 ms-3">
                                                                    <h5 className="mt-0">{service.name}</h5>
                                                                    <p className="text-muted mb-0">Horem ipsum dolor
                                                                        consectetuer Lorem
                                                                        simply dummy orem commo.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </Row>
                                </div>
                            </Col>
                        </div>
                    </Container>

                    <div className="container mt-100 mt-60">
                        <div style={{background: `url(${Map}) center center`}}>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="position-relative me-lg-5">
                                        <img src={About} className="rounded img-fluid mx-auto d-block" alt=""/>
                                        <div className="play-icon">
                                            <Link2 to="#" onClick={() => setModal(true)} data-type="youtube"
                                                   data-id="yba7hPeTSjk" className="play-btn lightbox">
                                                <i className="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                                            </Link2>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                    <div className="section-title">
                                        <h4 className="title mb-3">Tentang Kami
                                        </h4>
                                        <p className="text-muted">
                                            Mandala Cipta Jaya merupakan perusahaan yang bergerak dibidang manufaktur
                                            dan pengembangan karet untuk keperluan bantalan dermaga (Fender) dan karet
                                            untuk konstruksi.

                                            PT. MCJ ini adalah anak perusahaan dari PT. Mitra Mandala Jaya (PT.M2J).
                                            berfokus pada bidang pembuatan marine rubber fender sejak tahun 2012, dan
                                            telah memiliki sertifikat ISO 9001:2015 dan sertifikat produk penggunaan
                                            tanda SNI (SPPT SNI) sejak tahun 2015.

                                            Variasi produk yang kami miliki tidak hanya meliputi rubber fender saja.
                                            Untuk kepentingan dan estetika dermaga, kami memiliki Kademeter, Speed Bump,
                                            Cable Tray, Tangga monyet, serta Rubber Pad untuk keperluan pembangunan
                                            Jembatan.
                                            Kami percaya bahwa dengan menciptakan produk yang terbaik, akan menciptakan
                                            kualitas produk yang sempurna. Dan dengan produk yang sempurna, akan
                                            menciptakan kepuasan terhadap pelanggan kami. Dengan begitu akan tercipta
                                            hubungan bisnis yang memuaskan.


                                        </p>
                                        <div className="d-inline-block">
                                            <div className="pt-3 d-flex align-items-center border-top">
                                                <i className="uil uil-envelope text-primary me-2 fs-1"></i>
                                                <div className="content">
                                                    <p className="mb-0">Need More Help?</p>
                                                    <Link2 to="#" className="text-dark h6">Ask us your question</Link2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* popup video */}
                </section>
                {/*  End  */}

                {/* CTA Start */}
                <section className="section" style={{background: `url(${CTA}) center`}}>
                    <div className="bg-overlay"></div>
                    <Container>
                        <div className="row justify-content-center">
                            <div className="col">
                                <div className="section-title text-center">
                                    <h4 className="title text-white mb-3">Siap menjalin kerja sama dengan kami?</h4>
                                    <p className="text-white-50 mx-auto para-desc mb-0">.</p>

                                    <div className="mt-4 pt-2">
                                        <Link2 to="#" className="btn btn-primary">Get Started !</Link2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
                <section>
                    <Container>
                        <Row className="justify-content-center mt-5">
                            <Col>
                                <div className="section-title text-center mb-4 pb-2">
                                    <h4 className="title mb-3">Perusahaan Telah Mempercayai Kami</h4>
                                </div>
                            </Col>
                        </Row>
                        <Row>

                        </Row>
                    </Container>


                    <Swiper
                        freeMode={true}
                        spaceBetween={0}
                        loop={true}
                        breakpoints={{
                            320: {
                                slidesPerView: 5,
                                spaceBetween: 15,
                            },
                            768: {
                                slidesPerView: 8,
                                spaceBetween: 5,
                            },
                            1280: {
                                slidesPerView: 9,
                                spaceBetween: 5,
                            },
                            1440: {
                                slidesPerView: 9,
                                spaceBetween: 15,
                            },
                        }}
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                        }}
                        initialSlide={1}

                        centerInsufficientSlides={true}
                        modules={[Autoplay]}
                    >

                        {
                            clientLists.map((client) => {
                                return (
                                    <>
                                        <SwiperSlide
                                            key={client}>
                                            <img style={{maxWidth: 100, maxHeight: 100}} src={client}/>
                                        </SwiperSlide>
                                    </>
                                )
                            })
                        }
                    </Swiper>

                </section>
                {/*  CTA End  */}
                {/*<AgencyProject/>*/}
                <Contact/>
                {/* Footer Start */}
                <footer className="bg-footer">
                    <Container>
                        <Row>
                            <div className="col-12">
                                <div className="footer-py-60">
                                    <Row>
                                        <div className="col-lg-3 col-12 mb-lg-0 mb-md-4 pb-lg-0 pb-md-2">
                                            <div className={'mb-3'}>
                                                <Link2 to="#" className="logo-footer">
                                                    <img
                                                        src={'https://gilda.s3.nevaobjects.id/gilda/logos-mcu-mono.png'}
                                                        height="24" alt=""/>
                                                </Link2>
                                            </div>

                                            <div className={'d-flex'}>
                                                <ul className="list-unstyled social-icon text-sm-end foot-social-icon mb-0">

                                                    <li className="list-inline-item ms-1"><Link2 to="#"
                                                                                                 className="rounded"><i
                                                        className="uil uil-linkedin" title="Linkedin"></i></Link2></li>
                                                    <li className="list-inline-item ms-1"><Link2 to="#"
                                                                                                 className="rounded"><i
                                                        className="uil uil-facebook-f align-middle"
                                                        title="facebook"></i></Link2>
                                                    </li>
                                                    <li className="list-inline-item ms-1"><Link2 to="#"
                                                                                                 className="rounded"><i
                                                        className="uil uil-instagram align-middle"
                                                        title="instagram"></i></Link2>
                                                    </li>

                                                    <li className="list-inline-item ms-1"><Link2 to="#"
                                                                                                 className="rounded"><i
                                                        className="uil uil-envelope align-middle"
                                                        title="email"></i></Link2></li>
                                                    <li className="list-inline-item ms-1"><Link2 to="#"
                                                                                                 className="rounded"><i
                                                        className="uil uil-file align-middle" title="customization"></i></Link2>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                            <h5 className="footer-head">Our Services</h5>
                                            <ul className="list-unstyled footer-list mt-4">
                                                {
                                                    serviceList?.map((value) => {
                                                        return (
                                                            <>
                                                                <li><Link2 to="#" className="text-foot"><i
                                                                    className="uil uil-angle-right-b me-1"></i>{value.name}
                                                                </Link2></li>
                                                            </>
                                                        )
                                                    })
                                                }


                                            </ul>
                                        </div>

                                        <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                            <h5 className="footer-head"></h5>

                                            <p className="mt-4">Jl. Jababeka XVII B Blok U7L Kawasan Industri Jababeka
                                                RT. 0 RW. 0, Harjamekar, Cikarang Utara, Bekasi, Jawa Barat
                                            </p>
                                            <ul className="list-unstyled footer-list mt-3">
                                                <li>Phone: <a href="tel:+152534-468-854" className="text-light">+152
                                                    534-468-854</a></li>
                                                <li>Email: <a href="mailto:contact@example.com"
                                                              className="text-light">contact@example.com</a></li>
                                            </ul>
                                        </div>

                                        <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                            <h5 className="footer-head">Newsletter</h5>
                                            <p className="mt-4">Sign up and receive the latest tips via email.</p>
                                            <form>
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className="foot-subscribe mb-3">
                                                            <label className="form-label">Write your email <span
                                                                className="text-danger">*</span></label>
                                                            <input type="email" name="email" id="emailsubscribe"
                                                                   className="form-control rounded"
                                                                   placeholder="Your email : " required/>
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="d-grid">
                                                            <input type="submit" id="submitsubscribe" name="send"
                                                                   className="btn btn-soft-primary" value="Subscribe"/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </Row>
                    </Container>

                    <div className="footer-py-30 footer-bar">
                        <div className="container text-center">
                            <Row className="align-items-center justify-content-center">
                                <Col sm={12}>
                                    <div className="text-sm-start">
                                        <p className="mb-0">© {(new Date().getFullYear())}{" "} PT.Mandala Cipta Jaya
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </footer>
                {/* Back to top */}
                <Link to="home" id="back-to-top" className="back-to-top rounded-pill fs-5"><Icon.ArrowUp
                    className="fea icon-sm icons align-middle"/></Link>
                {/* Back to top  */}
            </div>
            {/* popup video */}
            <ModalVideo
                channel="youtube"
                isOpen={videoModal}
                videoId="yba7hPeTSjk"
                onClose={() => setModal(false)}
            />

        </>
    )

}

